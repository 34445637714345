import './ds-user-dropdown.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { apiEndpoint } from 'environment';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsUserDropdown {
    constructor(
        private router: Router,
        private toastService: ToastService,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private websiteService: WebsiteService) { }

    @bindable user;
    @bindable viewingAsAdmin: boolean;
    canAccessAdminPanel;
    switchElement;
    environment = apiEndpoint();
    avatarDialogModule;
    otherAvatarDialogShow;
    openDropdown = false;
    pages;
    customerPortalRoute;
    private windowWidth: number;
    private previousEvent: string;
    private sizeSubscription: Subscription;
    private innerList: HTMLElement;
    private arrow: HTMLElement;

    async attached() {
        this.innerList = document.querySelector('.inner-list');
        this.arrow = document.querySelector('.arrow-down');
        [this.user, this.pages] = await Promise.all([
            this.sessionService.refreshProfile(),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);

        this.viewingAsAdmin = await this.sessionService.getAdminView() && await this.sessionService.checkRolesForPanelAccess();

        this.customerPortalRoute = this.pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal';

        if (this.user) {
            await this.checkCanAccessAdminPanel();
            if (this.viewingAsAdmin) {
                this.switchElement = document.getElementById('view-switch');
                if (this.switchElement) {
                    this.switchElement.checked = this.viewingAsAdmin;
                }
            }

            this.eventAggregator.publish('user-updated', { user: this.user });

            this.eventAggregator.subscribe('avatar-dialog-open', payload => {
                this.otherAvatarDialogShow = payload.avatarDialogOpen;
            });
            this.eventAggregator.subscribe('open-dialog', () => {
                this.handleAvatarDialogOpen();
            });
            this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.sizeSubscription = this.eventAggregator.subscribe('size-changed', payload => {
                this.windowWidth = payload.width;
                if (this.windowWidth > 992) {
                    this.innerList.setAttribute('style', '');
                    this.arrow.setAttribute('style', '');
                }
            });
            this.previousEvent = 'click';
        }
    }

    detached() {
        this.sizeSubscription?.dispose();
    }

    async userChanged() {
        await this.checkCanAccessAdminPanel();
        if (this.user && this.viewingAsAdmin) {
            setTimeout(() => {
                if (!this.switchElement) {
                    this.switchElement = document.getElementById('view-switch');
                }
                this.switchElement.checked = this.viewingAsAdmin;
            }, 500);
        }
    }

    publishRouteChange(option = '') {
        this.router.navigate(`/${this.customerPortalRoute}/${ option }`);
        this.eventAggregator.publish('route-updated', { option: option });
    }

    toggleOpenDropdown(event) {
        if (this.windowWidth <= 992) {
            if (event.type === 'focusout') {
                this.openDropdown = false;
            } else {
                if (this.previousEvent === 'click') {
                    this.openDropdown = !this.openDropdown;
                }
            }
            this.previousEvent = event.type;

            if (!this.openDropdown) {
                this.innerList.style.display = 'none';
                if (this.arrow) this.arrow.style.transform = 'rotate(0deg)';
            } else {
                this.innerList.style.display = 'block';
                if (this.arrow) this.arrow.style.transform = 'rotate(180deg)';
                this.innerList.focus();
            }
        }
    }

    async checkCanAccessAdminPanel() {
        this.canAccessAdminPanel = await this.sessionService.checkRolesForPanelAccess();
    }

    viewingAsAdminChanged() {
        this.sessionService.saveAdminView(this.viewingAsAdmin);
    }

    viewAsAdmin() {
        if (!this.switchElement) {
            this.switchElement = document.getElementById('view-switch');
        }
        this.switchElement.checked = !this.switchElement.checked;
        this.viewingAsAdmin = this.switchElement.checked;
    }

    handleAvatarDialogOpen() {
        if (!this.otherAvatarDialogShow) {
            this.avatarDialogModule.dialog.open();
        }
    }

    async showIntercomMessage(type: string) {
        if (window.Intercom) {
            window.Intercom('showNewMessage', `I would like to ${type} crypto.`);
        }
    }

    updateRoute(routeOption: string) {
        this.eventAggregator.publish('route-updated', { option: routeOption });
    }

    async logOut() {
        await this.sessionService.logout();
        this.viewAsUser();
        await this.toastService.showToast('You have been logged out', 'Please log back in to see your profile.', 'info');
        this.router.navigate('');
    }

    viewAsUser() {
        this.switchElement = this.getSwitchAdminElement();
        if (this.viewingAsAdmin) {
            this.switchElement.checked = false;
            this.viewingAsAdmin = false;
        }
    }

    getSwitchAdminElement() {
        if (!this.switchElement) {
            this.switchElement = document.getElementById('view-switch');
        }
        return this.switchElement;
    }
}
