import { PageContentArea } from 'services/models/page/pageContentArea';
import { baseUrl, debug, fingerprintApiKey, fingerprintDomainUrl, environmentName } from 'environment';
import numeral from 'numeral';
import sanitizeHtml from 'sanitize-html';
import FingerprintJS, { defaultEndpoint, defaultScriptUrlPattern } from '@fingerprintjs/fingerprintjs-pro';
import { Router } from 'aurelia-router';
import { ToastService } from 'services/toast-service';
import * as constants from 'resources/constants';
import { RateLimiter } from 'limiter';

export class Helper {
    signInOptions = ['login', 'sign_up', 'CompleteRegistration'];
    fingerprint;
    pendingRequests = new Map();
    serviceState = {};
    rateLimiter;

    constructor(private toastService: ToastService) { }

    phoneFormat(value, code) {
        if (!value) {
            return '-';
        }

        const aux = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        const part1 = aux[1] || '';
        const part2 = aux[2] || '';
        const part3 = aux[3] || '';

        return `+${code ?? ''}${part1 ? ` ${part1}` : ''}${part2 ? `-${part2}` : ''}${part3 ? `-${part3}` : ''}`;
    }

    handleGtagEvent = (eventName: string, itemObject: any, currency: string, price: number, couponCode?: string, method?: string) => {
        this.removeGtagEvent();
        if (!itemObject && !price && !this.signInOptions.includes(eventName)) return;
        const script = document.createElement('script');
        script.setAttribute('id', 'ga4-event');
        script.setAttribute('type', 'text/javascript');
        if (itemObject && !Array.isArray(itemObject)) itemObject = [itemObject];
        script.innerHTML = `window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'G-EKFP6MHXL0');
                            
                            gtag('event', '${eventName}'`;

        if (!this.signInOptions.includes(eventName) || (this.signInOptions.includes(eventName) && (debug() || method))) script.innerHTML += ', {';

        price = Math.abs(price);
        const formattedPrice = itemObject && !Array.isArray(itemObject) && itemObject.productCategory?.name === 'Currency' ? numeral(price).format('0.00[000]') : numeral(price).format('0.00');

        if (price) {
            script.innerHTML += `'currency': "${currency}",
                                 'value': ${formattedPrice}`;
        }

        if (debug()) {
            if (price) script.innerHTML += ', ';
            script.innerHTML += '\'debug_mode\': true';
        }

        if (couponCode) {
            if (price || debug()) script.innerHTML += ', ';
            script.innerHTML += `'coupon': "${couponCode}"`;
        }

        if (itemObject?.length) {
            if (price || debug() || couponCode) script.innerHTML += ', ';
            script.innerHTML += '\'items\': [';

            for (const [index, item] of Array.from(itemObject as any[]).entries()) {
                script.innerHTML += `{
                                        'item_id': "${item.id}",
                                        'item_name': "${item.name}",\n`;
                if (itemObject.position) script.innerHTML += `'index': ${item.position},`;
                if (itemObject.internalId) script.innerHTML += `'item_variant': "${item.internalId}",`;

                script.innerHTML += '\'affiliation\': "DivicaSales",\n';

                [item.productCategory?.name, item.game?.name].filter(x => x).forEach((x, i) => script.innerHTML += `'item_category${i ? i + 1 : ''}' : "${x}",\n`);

                script.innerHTML += `'price': ${formattedPrice},
                                    'quantity': ${eventName === 'view_item' ? 1 : parseInt(item.quantity?.toString()?.replaceAll(',', '') ?? item.tempQuantity?.toString()?.replaceAll(',', ''))}
                                    }`;
                if (index < itemObject.length - 1) script.innerHTML += ',';
            }

            script.innerHTML += ']';
        }

        if (method) {
            if (debug()) script.innerHTML += ', ';
            script.innerHTML += `'method': "${method}"`;
        }

        if (debug() || !this.signInOptions.includes(eventName) || (this.signInOptions.includes(eventName) && method)) script.innerHTML += '}';
        script.innerHTML += ');';

        document.body.appendChild(script);
    };

    toRoute = (string) => string.toLowerCase().replaceAll(' ', '-');

    removeGtagEvent = () => document.getElementById('ga4-event')?.remove();

    handleFacebookPixelEvent = (eventName, itemObject?, currency?, price?) => {
        if (debug()) return;
        this.removeFacebookPixelEvent();
        const script = document.createElement('script');
        script.setAttribute('id', 'fb-pixel-event');
        script.setAttribute('type', 'text/javascript');
        if (itemObject && !Array.isArray(itemObject)) itemObject = [itemObject];
        script.innerHTML = `fbq('track', '${eventName}'`;

        if (!this.signInOptions.includes(eventName)) {
            script.innerHTML += ', {';

            if (currency) {
                script.innerHTML += `currency: "${currency}"`;
            }

            if (price) {
                script.innerHTML += `, value: ${price}`;
            }

            if (itemObject?.length) {
                script.innerHTML += `, content_ids: [${itemObject.map(x => x.id)}]`;

                script.innerHTML += ', contents: [';

                for (const [index, item] of Array.from(itemObject as any[]).entries()) {
                    script.innerHTML += `{
                                            id: "${item.id}",
                                            quantity: ${parseInt(item.quantity?.toString()?.replaceAll(',', '') ?? item.tempQuantity?.toString()?.replaceAll(',', ''))}
                                        }`;
                    if (index < itemObject.length - 1) script.innerHTML += ',';
                }

                script.innerHTML += ']';

                if (eventName === 'InitiateCheckout') {
                    script.innerHTML += `, num_items: ${itemObject.length}`;
                }

                if (eventName === 'AddToCart') {
                    script.innerHTML += ', content_type: "product"';
                }
            }

            script.innerHTML += '}';
        }

        script.innerHTML += ');';

        document.body.appendChild(script);
    };

    removeFacebookPixelEvent = () => document.getElementById('fb-pixel-event')?.remove();

    addPrerenderMetaTagForRedirect(url: string) {
        const metaPrerenderStatusCode = document.createElement('meta');
        metaPrerenderStatusCode.setAttribute('id', 'prerender-status-code');
        metaPrerenderStatusCode.setAttribute('name', 'prerender-status-code');
        metaPrerenderStatusCode.setAttribute('content', '301');

        const metaPrerenderHeader = document.createElement('meta');
        metaPrerenderHeader.setAttribute('id', 'prerender-header');
        metaPrerenderHeader.setAttribute('content', `Location: ${baseUrl().slice(0, -1)}${url}`);

        document.head.appendChild(metaPrerenderStatusCode);
        document.head.appendChild(metaPrerenderHeader);
    }

    removePrerenderMetaTagForRedirect() {
        document.getElementById('prerender-header')?.remove();
        document.getElementById('prerender-status-code')?.remove();
    }

    injectScript = (id: string, src: string, async = false, crossorigin = false) => {
        const el = document.getElementById(id);
        if (el) return;
        const script = document.createElement('script');
        script.setAttribute('id', id);
        script.setAttribute('type', 'text/javascript');
        if (async) script.setAttribute('async', 'true');
        if (crossorigin) script.setAttribute('crossorigin', 'anonymous');
        script.setAttribute('src', src);
        document.body.appendChild(script);
    };

    // ----- DOM Functionality -----

    getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    // Needs to have eventAggregator imported to work.
    handleMainPageScroll(obj, isOpen = true) {
        const disableMainPageScroll = document.getElementById('main-page-host');
        if (isOpen) {
            disableMainPageScroll.style.overflow = 'hidden';
        } else {
            disableMainPageScroll ? disableMainPageScroll.style.overflow = null : '';
        }
        obj.eventAggregator.publish('drawer-closed', { isClosed: false });
    }

    // Needs to have eventAggregator imported to work.
    resizeByNavbar(obj, parent, navSelector = '#navigation-bar', height = null) {
        const navbar = document.querySelector(navSelector);
        const app = document.querySelector(parent);
        this.changePaddingByNavbar(app, navbar, height);
        obj.eventAggregator.publish('observe-element', ({ selector: navSelector }));
        obj.navBarSubscriber = obj.eventAggregator.subscribe(`size-changed-${navSelector.removeSelectorSymbol()}`, () => this.changePaddingByNavbar(app, navbar, height));
    }

    changePaddingByNavbar = (app, navbar, height) => {
        app.style.marginTop = navbar.clientHeight.toString() + 'px';
        if (!height) return;
        app.style.height = `calc( ${height} - ${app.style.marginTop})`;
    };

    // ----- Object Functionality ----

    /*
    This function will recieve an object, and set the specified value to all properties that include any of the specified names, and not modify those that include the
    string that are inside of the exclude array, if it exists.
    */
    setPropertiesByName(obj, names, value, exclude = []) {
        this.getPropertiesByName(obj, names, exclude).forEach(x => obj[x] = value);
    }

    getPropertiesByName(obj, names, exclude = []) {
        return Object.keys(obj)?.filter(x => this.includesWithout(x, names, exclude));
    }

    getVariablesByName(obj, names, exclude = []) {
        return this.getPropertiesByName(obj, names, exclude).map(x => obj[x]);
    }

    // ----- General Functionality ----

    getResolutions(obj, suffix = '') {
        const resolutions = Object.keys(constants).map(x => x.split('device__')[1]).filter(x => x);

        resolutions.forEach(x => obj[x + suffix] = constants[`device__${x}`]);
    }

    debounce = (obj, fieldName: string, timeoutName: string, time = 2000, callback = null) => {
        obj[fieldName] = true;
        if (obj[timeoutName]) {
            clearTimeout(obj[timeoutName]);
            obj[timeoutName] = null;
        }
        obj[timeoutName] ??= setTimeout(async() => {
            obj[timeoutName] = null;
            obj[`${fieldName}Inner`] = true;
            const response = await callback?.();
            obj[`${fieldName}Inner`] = false;
            if (!response) obj[fieldName] = false;
        }, time);
    };

    disposeAll = (events) => {
        events?.forEach(x => {
            x?.dispose();
        });
    };

    disposeAllSubscribers(obj) {
        this.disposeAll(this.getVariablesByName(obj, ['Subscriber']));
    }

    getTrustPilotStarRatingVariables(obj, pageContentArea: PageContentArea[]) {
        obj.trustPilotStarRating = pageContentArea.find(x => x.key === 'HOME_TRUST_PILOT_RATING')?.markup ?? '4.8';
        obj.trustPilotStarRating = this.sanitizeHtml(obj.trustPilotStarRating, true);
        obj.trustPilotStarRating = Number(obj.trustPilotStarRating).toFixed(1).toFloat();
        obj.amountOfStars = obj.trustPilotStarRating.toInt();
        const checker = (obj.trustPilotStarRating % 1 !== 0) && (obj.trustPilotStarRating - obj.trustPilotStarRating?.toInt()).toFloat().toFixed(1);
        obj.halfStar = checker.toFloat() < 0.6;
        obj.semiSesquiStar = !obj.halfStar;
    }

    // ----- String Functionality -----

    isFile = (str: string, noRoute = false) => /.*\.[^/\\]+$/.test(str) && (noRoute ? this.excludeAll(str, ['/', '\\']) : true);

    camelize = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

    removeSelectorSymbol = (selector) => selector.replace(/^[#.:[]?([a-zA-Z_-][\w-]*).*/, '$1');

    toPascal = (str) => this.toCapitalize(this.camelize(str), 'first');

    toCapitalize = (value: string, type: string) => {
        if (!value) return '';
        if (type === 'first') {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        const sentence = value.split(' ');
        return sentence.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };

    // Check if the string is empty
    isEmpty = (str: string) => (!str?.length);

    // ----- Array Functionality -----

    filterOutByArray = (array, arrayToFilter) => array.filter(x => arrayToFilter.some(y => x.paymentMethod ? x.paymentMethod.reference.includes(y) : x.includes(y)));

    isBoolean = (value) => value === false || value === true;

    sanitizeHtml = (value, noHtml = false) => {
        if (!value) return '';
        const params = {
            allowedTags: ['div', 'p', 'img', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'section', 'li', 'ol', 'ul',
                'br', 'i', 'span', 'strong', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr'],
            allowedAttributes: {
                a: ['href', 'name', 'target', 'class'],
                img: ['src', 'alt', 'class']
            },
            selfClosing: ['img'],
            allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'data']
        };

        if (noHtml) {
            params.allowedTags = [];
            params.allowedAttributes = {} as { a: string[]; img: string[]; };
        }
        return sanitizeHtml(value, params);
    };

    generateRandomString = (length = 8) => Math.random().toString(36).substring(0, length);

    includesWithout(arr, includes, excludes, explicit = false) {
        const func = explicit ? 'every' : 'some';
        return this.includesBy(arr, includes, func) && this.excludeAll(arr, excludes);
    }

    includesBy = (arr, values, func) => values[func](x => arr?.includes(x));

    includesAll = (arr, values) => this.includesBy(arr, values, 'every');

    includesSome = (arr, values) => this.includesBy(arr, values, 'some');

    excludeAll = (arr, values) => values?.every(x => !arr.includes(x));

    //Convert number without comma from string to number type
    convertNumberWithoutComma = (value, type = null) => {
        value = value?.toString().replaceAll(',', '');
        switch (type) {
            case 'float':
                return parseFloat(value);
            case 'int':
                return parseInt(value);
            default:
                return value;
        }
    };

    // Returns an array of numbers by the given amount
    range = (amount, startFromOne = false, excludeNumber?) => {
        let keys = [...Array(amount).keys()];
        keys = startFromOne ? keys.map(i => i + 1) : keys;
        if (typeof excludeNumber === 'number') excludeNumber = [excludeNumber];
        if (excludeNumber) return keys.filter(num => !excludeNumber.includes(num));
        return keys;
    };

    handlePasteNumberValidation = (event, element) => {
        if (!element) return;
        const clipData = event.clipboardData || (window as any).clipboardData;
        const text = clipData?.getData('text/plain');
        if (!text?.toLowerCase()?.includes('e') && !text?.includes('+') && !text?.includes('-')) return true;
        event?.preventDefault();
    };

    fetchFingerprintForUser = async() => {
        if (environmentName() === 'local') return;
        if (!this.fingerprint) {
            this.fingerprint = await FingerprintJS.load({
                apiKey: fingerprintApiKey(),
                scriptUrlPattern: [
                    `${fingerprintDomainUrl()}web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
                    defaultScriptUrlPattern
                ],
                endpoint: [
                    fingerprintDomainUrl().slice(0, -1),
                    defaultEndpoint
                ]
            });
        }
        const result = await this.fingerprint.get();
        if (!result) return;
        return result.visitorId;
    };

    saveWindowLocalStorageValue(localStorage, value) {
        window.localStorage[localStorage] = value;
    }

    getWindowLocalStorageValue(localStorage) {
        return window.localStorage[localStorage];
    }

    destroyWindowLocalStorageValue(localStorage) {
        window.localStorage.removeItem(localStorage);
    }

    handlePrerender404 = (router: Router) => {
        const prerenderMeta = document.getElementById('prerender-404-status-code');
        if (router.currentInstruction.config.name === '404' && !prerenderMeta) {
            const prerenderMetaTag = document.createElement('meta');
            prerenderMetaTag.setAttribute('id', 'prerender-404-status-code');
            prerenderMetaTag.setAttribute('name', 'prerender-status-code');
            prerenderMetaTag.setAttribute('content', '404');
            document.head.appendChild(prerenderMetaTag);
        }
    };

    async handlePendingRequest(component, apiCall) {
        let isRequestPending = this.pendingRequests.get(component);
        if (isRequestPending === undefined) {
            isRequestPending = false;
            this.pendingRequests.set(component, isRequestPending);
        }

        while (isRequestPending) {
            await new Promise(resolve => setTimeout(resolve, 100));
            isRequestPending = this.pendingRequests.get(component);
        }

        this.pendingRequests.set(component, true);

        try {
            return await apiCall();
        } finally {
            this.pendingRequests.set(component, false);
        }
    }

    invalidateServiceData(serviceName: string) {
        this.serviceState[serviceName] = null;
    }

    async fetchData(api, path, serviceName) {
        if (!this.serviceState[serviceName]) {
            this.serviceState[serviceName] = {
                responseData: null,
                isRequestPending: false,
                requestQueue: []
            };
        }

        const { responseData, isRequestPending, requestQueue } = this.serviceState[serviceName];

        if (responseData) {
            return responseData;
        }

        if (isRequestPending) {
            return new Promise((resolve) => {
                requestQueue.push(resolve);
            });
        }

        try {
            this.serviceState[serviceName].isRequestPending = true;
            this.serviceState[serviceName].responseData = await api.doGet(`${path}`);
            this.processRequestQueue(serviceName);
            return this.serviceState[serviceName].responseData;
        } finally {
            this.serviceState[serviceName].isRequestPending = false;
        }
    }

    clearServiceQueueState(serviceName) {
        this.serviceState[serviceName] = null;
    }

    processRequestQueue(serviceName) {
        const { responseData, requestQueue } = this.serviceState[serviceName];
        while (requestQueue.length > 0) {
            const resolve = requestQueue.shift();
            if (resolve) {
                resolve(responseData);
            }
        }
    }

    combineApplicationLdJsonSchemasIntoOne = (schema, router = null) => {
        const existingGlobalSchema = document.getElementById('divicasales-schema');
        const existingGlobalSchemaText = existingGlobalSchema?.textContent || existingGlobalSchema?.innerText;
        const existingGlobalSchemaParsed = existingGlobalSchemaText ? JSON.parse(existingGlobalSchemaText) : null;
        if (existingGlobalSchema) existingGlobalSchema.remove();

        const globalSchema = document.createElement('script');
        globalSchema.setAttribute('id', 'divicasales-schema');
        globalSchema.type = 'application/ld+json';
        const combinedSchemas = `{
            "@context": "https://schema.org/",
            "@graph": [
                ${schema}${existingGlobalSchemaParsed ? ',' : ''}
                ${existingGlobalSchemaParsed?.['@graph'] ? existingGlobalSchemaParsed['@graph'].map(obj => JSON.stringify(obj)).join(',') : ''}
            ]
        }`;

        //Check for duplicated schemas and remove
        const combinedSchemasClone = JSON.parse(combinedSchemas);
        const seenTypes = {};
        const reversedSchema = combinedSchemasClone['@graph'].slice().reverse();

        combinedSchemasClone['@graph'] = reversedSchema.filter(item => {
            if (seenTypes[item['@type']]) return false;
            seenTypes[item['@type']] = true;
            return true;
        }).reverse();

        let finalizedCombinedSchemas = JSON.stringify(combinedSchemasClone, null, 4);

        if (router) {
            const schemasArray = [];

            if (!router.currentInstruction.config.hasBlogPostSchema) {
                schemasArray.push('BlogPosting', 'Person');
            }

            if (!router.currentInstruction.config.hasProductSchema) {
                schemasArray.push('Product', 'Offer', 'AggregateRating', 'Brand');
            }

            finalizedCombinedSchemas = this.removeFromGlobalSchema(combinedSchemasClone, schemasArray);
        }

        globalSchema.innerHTML = finalizedCombinedSchemas;
        document.head.appendChild(globalSchema);
    };

    removeFromGlobalSchema = (mainSchema, schemasArray) => {
        if (!mainSchema) return;
        mainSchema['@graph'] = mainSchema['@graph'].filter(obj => !schemasArray.includes(obj['@type']));
        return JSON.stringify(mainSchema, null, 4);
    };

    createOrSelectElement(query, parent) {
        let element = parent.querySelector(query);
        if (element) return element;
        element = this.createElementFromSelector(query);
        parent.appendChild(element);
        return element;
    }

    createElementFromSelector(selector) {
        const pattern = /^(.*?)(?:#(.*?))?(?:\.(.*?))?(?:@(.*?)(?:=(.*?))?)?$/;
        const matches = selector.match(pattern);
        const element = document.createElement(matches[1] || 'div');
        if (matches[2]) element.id = matches[2];
        if (matches[3]) element.className = matches[3];
        if (matches[4]) element.setAttribute(matches[4], matches[5] || '');
        return element;
    }

    objectMap(obj, callback) {
        return Object.keys(obj).reduce((newObj, key) => {
            newObj[key] = callback(obj[key]);
            return newObj;
        }, {});
    }

    validatorCheckOneCondition = (field, results) => !results.some(x => x.propertyName === field && !x.valid);

    addBrowserInfoData() {
        return {
            browserAcceptHeader: 'application/json',
            browserJavaEnabled: window.navigator.javaEnabled(),
            browserJavaScriptEnabled: true,
            browserLanguage: window.navigator.language,
            browserColorDepth: window.screen.colorDepth.toString(),
            browserTZ: new Date().getTimezoneOffset().toString(),
            browserScreenWidth: window.screen.width.toString(),
            browserScreenHeight: window.screen.height.toString(),
            browserUserAgent: window.navigator.userAgent
        };
    }

    getCardImageType = (cardType) => {
        const cardTypeMappings = {
            'vi': 'visa',
            've': 'visa',
            'vd': 'visa',
            'visa': 'visa',
            'visaelectron': 'visa',
            'mc': 'mastercard',
            'mastercard': 'mastercard',
            'am': 'amex',
            'amex': 'amex',
            'american express': 'amex',
            'dc': 'diners',
            'diners': 'diners',
            'diners club': 'diners',
            'di': 'discover',
            'discover': 'discover',
            'maestro': 'maestro',
            'jcb': 'jcb',
            'mada': 'mada'
        };

        const simplifiedType = cardTypeMappings[cardType?.toLowerCase()] || 'generic';
        return `/payment-methods/${simplifiedType}.svg`;
    };

    addLeadingZeroToNumber = (number) => number < 10 ? `0${number}` : number;

    validateCondition(condition, message, onFail = null, type = 'Error', title = null) {
        if (condition) return true;
        this.toastService.showToast(type, message, title);
        onFail?.();
        return false;
    }

    /**
     * Checks whether the object has a value assigned in the specified properties.
     * @param {object} obj The object to validate.
     * @param {string[]} properties The properties to check.
     * @param ignoreFalsy Whether to check specifically by null and undefined, or all falsy values.
     * @returns A boolean describing if the object contains all the properties.
     */
    hasAllProperties = (obj, properties, ignoreFalsy = false) => {
        if (!obj) return false;
        return properties.every(x => ignoreFalsy
            ? obj[x] !== null && obj[x] !== undefined
            : obj[x]
        );
    };

    fetchIPsForCustomer = async() => {
        if (debug()) return;
        let ipv4Address;
        let ipv6Address;

        try {
            const ipv4Fetch = await fetch('https://api.ipify.org');
            ipv4Address = await ipv4Fetch.text();
        } catch (error) {
            //skip
        }

        try {
            const ipv6Fetch = await fetch('https://api6.ipify.org');
            ipv6Address = await ipv6Fetch.text();
        } catch (error) {
            //skip
        }

        return { ipv4Address, ipv6Address };
    };

    convertShortYearToFullYear = (year) => {
        const currentYear = new Date().getFullYear();
        const century = Math.floor(currentYear / 100);
        return century * 100 + parseInt(year, 10);
    };

    initializeRateLimitingForAutomaticMethods = () => this.rateLimiter = new RateLimiter({ tokensPerInterval: 2, interval: 900000, fireImmediately: true });

    reduceRateLimitTokenForAutomaticMethods = async() => await this.rateLimiter.removeTokens(1);
}
