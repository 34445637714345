import './verification.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { ImageService } from 'services/image-service';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { QueryParamsValueConverter } from 'resources/value-converters/query-params';
import { WebsiteService } from 'services/website-service';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class Verification {
    constructor(
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private customerService: CustomerService,
        private toastService: ToastService,
        private router: Router,
        private imageService: ImageService,
        private validator: ValidationController,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private queryParamsValueConverter: QueryParamsValueConverter,
        private websiteService: WebsiteService,
        private pageContentAreaService: PageContentAreaService,
    ) {
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
    }

    @bindable private user;
    userSubscriber;
    veriffSubscriber;

    phoneVerification = false;
    emailVerification = false;
    idVerification = false;
    addressVerification = false;

    requestedPhoneUpdate = false;
    requestedEmailUpdate = false;
    requestedAddressUpdate = false;
    requestedIdUpdate = false;
    showSupportMessage = false;

    showErrorMark;
    showCheckMark;
    showPendingMark;
    stopWatch1;
    stopWatch2;
    emailFocusInStopWatch;
    timeouts;
    loading;
    emailSent;
    pageLoading;
    fileUploaded;
    addressUploading;
    isAddressInReview;
    selectedFiles;
    fileName;
    rules;
    showEmailValidationError = false;
    addressText;
    addressState;
    poaHidden = true;
    activeVerificationTabs = {
        'phone': false,
        'email': false,
        'id': false,
        'address': false
    };

    userPhone;
    userVeriffData;
    dsVeriff;
    urlParams;
    dataRouter;
    pages;
    params;
    customerPortalPage;
    requestingEmail = false;
    veriffViewModel;
    idVeriffViewModel;
    fileHolderElement;
    tempDisabled;
    phoneElement;

    async activate(params) {
        [this.user, this.pages] = await Promise.all([
            this.sessionService.refreshProfile(),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);
        this.params = params;

        if (!this.user) {
            const homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';
            this.router.navigate(params.email && params.token ? `${homePageRoute}/?email=${params.email}&token=${params.token}` : homePageRoute);
            return;
        } else {
            this.user.emailInReview = await this.sessionService.getEmailInReview();
            if (this.user.emailInReview) {
                this.emailSent = true;
            }

            this.customerPortalPage = this.pages.find(x => x.name === 'Customer Portal');
            await this.pageContentAreaService.getByPageId(this.customerPortalPage?.id);
            this.dataRouter = this.router.routes.find(route => route.name === 'verification').data;
        }
    }

    async attached() {
        this.pageLoading = true;
        this.urlParams = this.queryParamsValueConverter.toView(window.location.href);
        this.user = this.user ?? await this.sessionService.refreshProfile();

        if (!this.user) {
            const homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';
            this.router.navigate(this.urlParams.email && this.params.token ? `${homePageRoute}?email=${this.urlParams.email}&token=${this.params.token}` : homePageRoute);
            return;
        }

        [this.userVeriffData, this.isAddressInReview] = await Promise.all([
            this.customerService.getVeriffUserData(this.user.id),
            this.sessionService.getAddressVerificationInReview(this.user.id)
        ]);

        if (this.isAddressInReview) {
            this.fileName = 'Under review';
            const fileInput = document.getElementById('fileHolder');
            fileInput.classList.add('input-password_warning');
        } else {
            this.fileName = 'Please select document';
        }
        this.handleContainerClick();
        this.handleEventSubscriptions();
        this.handleEmailConfirmation();
        this.pageLoading = false;
    }

    detached() {
        this.userSubscriber?.dispose();
        this.veriffSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async(payload) => {
            this.user = payload.user;
            if (this.user) {
                this.user.emailInReview = await this.sessionService.getEmailInReview();
                this.user.phoneNumberInReview = document.getElementById('code') ? true : false;
                this.user.idVerificationInReview = await this.sessionService.getIdVerificationInReview(this.user.id);
                this.user.addressVerificationInReview = await this.sessionService.getAddressVerificationInReview(this.user.id);
                this.user.selfieVerificationInReview = await this.sessionService.getSelfieVerificationInReview(this.user.id);
            }
        });

        this.veriffSubscriber = this.eventAggregator.subscribe('veriff-verification', async payload => {
            if (payload.idVerified) {
                this.user.idVerified = payload.idVerified;
                this.user = await this.sessionService.refreshProfile();
            }
        });
    }

    async handleEmailConfirmation() {
        if (!this.user?.emailConfirmed && this.urlParams?.email && this.router.currentInstruction.queryParams.token) {
            try {
                const successful = await this.customerService.confirmEmail(this.urlParams.email, this.router.currentInstruction.queryParams.token);
                if (successful) {
                    this.toastService.showToast('Success!', `Thank you ${this.user.firstName}, your email has been verified successfully.`, 'success');
                    this.user.emailConfirmed = true;
                    this.eventAggregator.publish('user-updated', { user: this.user });
                } else {
                    this.toastService.showToast('Error', 'Email token invalid or expired. Please ensure you open the latest email verification link or try again.', 'error');
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    handleContainerClick() {
        document.getElementById('verification').onclick = async(e) => {
            if ((e.target as HTMLElement).className.includes('address-container') && !this.user.addressVerified) {
                await this.veriffViewModel.startVerification('poa');
            } else if ((e.target as HTMLElement).className.includes('id-verification-container') && !this.user.idVerified) {
                await this.idVeriffViewModel.startVerification();
            }
        };
    }

    async toggleVerificationTab(tab: 'phone' | 'email' | 'address' | 'id', ev?: PointerEvent) {
        const isIcon = (<HTMLElement>ev?.target)?.className?.includes('material-icons-outlined');
        switch (tab) {
            case 'phone':
                if (!isIcon) {
                    this.phoneVerification = true;
                }
                break;
            case 'email':
                if (!isIcon) {
                    this.emailVerification = true;
                }
                break;
            case 'address':
                if (this.user.idVerified) {
                    if (!isIcon && this.poaHidden) {
                        this.addressVerification = true;
                    }
                }
                break;
            case 'id':
                this.idVerification = !this.idVerification;
                break;
        }
    }

    async phoneVerificationOpenTab() {
        const userTemp = await this.sessionService.refreshProfile();
        this.phoneVerification = !this.phoneVerification;
        if (!this.phoneVerification && this.phoneElement.verificationStage === this.phoneElement.stages.ENTERING_PHONE && userTemp.phoneNumberConfirmed) {
            this.eventAggregator.publish('user-updated', { user: userTemp });
        }
    }

    resetPhoneNumber() {
        this.user.phoneNumberConfirmed = false;
        this.user.phoneNumberInReview = false;
        this.userPhone.phoneNumberConfirmed = false;
        this.userPhone.phoneNumberInReview = false;
        this.phoneElement.clearPhoneInput();
        this.phoneElement.clearAuthCode();
        this.phoneElement.verificationStage = this.phoneElement.stages.ENTERING_PHONE;
    }

    async emailValidated() {
        await this.validator.reset();
        ValidationRules.ensure('email').matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this.user);
        this.rules = await this.validator.validate();
        const matchesEmail = this.rules.results[0].valid;
        if (!matchesEmail) {
            this.showEmailValidationError = this.showErrorMark = true;
            this.loading = false;
            return;
        }
        this.loading = false;
        const response = await this.updateInformation();
        if (response) {
            this.sendConfirmationEmail();
        }
    }

    async emailUpdatedOnKeyPress(ev) {
        this.showErrorMark = this.showCheckMark = this.showPendingMark = this.loading = this.showEmailValidationError = false;
        this.timeouts = [this.stopWatch1, this.stopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev?.key === 'Enter') {
            this.emailValidated();
            return;
        } else {
            if (this.user.email !== '') {
                this.stopWatch1 = setTimeout(async() => {
                    this.loading = true;
                    this.stopWatch2 = setTimeout(async() => {
                        this.emailValidated();
                    }, 2000);
                }, 2000);
            }
        }
    }

    emailUpdatedOnFocusIn() {
        this.showErrorMark = false;
        this.emailFocusInStopWatch = setTimeout(() => {
            this.emailUpdatedOnKeyPress({ key: 'Enter' });
        });
    }

    async sendConfirmationEmail() {
        if (this.requestingEmail) return;
        this.requestingEmail = true;
        try {
            const response = await this.customerService.requestEmailConfirmation();
            if (!response.success) return;
            this.user.emailInReview = this.emailSent = true;
            this.sessionService.saveEmailInReview(this.user.emailInReview);
            this.eventAggregator.publish('user-updated', { user: this.user });
            this.toastService.showToast('Success!', 'A verification email has been sent to your inbox.', 'success');
        } catch (e) {
            console.log(e);
        } finally {
            setTimeout(() => this.requestingEmail = false, 5000);
        }
    }

    async cancelEmailVerification() {
        this.user.emailInReview = false;
        await this.sessionService.saveEmailInReview(this.user.emailInReview);
        this.emailSent = false;
        this.toggleVerificationTab('email');
    }

    async updateInformation() {
        try {
            const response = await this.customerService.updateInformation(this.user);
            if (response) {
                response.idVerificationInReview = await this.sessionService.getIdVerificationInReview(this.user.id);
                response.addressVerificationInReview = await this.sessionService.getAddressVerificationInReview(this.user.id);
                response.selfieVerificationInReview = await this.sessionService.getSelfieVerificationInReview(this.user.id);
                this.user = response;
                this.eventAggregator.publish('user-updated', { user: this.user });
                this.toastService.showToast('Updated profile successfully', 'Updated Profile', 'success');
                return response;
            } else {
                this.toastService.showToast('Failed to update user profile', 'Please check that all fields are valid.', 'error');
            }
        } catch (e) {
            console.log(e);
            this.toastService.showToast('Failed to update user profile', 'Please check that all fields are valid.', 'error');
        }
    }

    showMessage(section) {
        switch (section) {
            case 'phone':
                this.requestedPhoneUpdate = true;
                break;
            case 'email':
                this.requestedEmailUpdate = true;
                break;
            case 'address':
                this.requestedAddressUpdate = true;
                break;
            case 'id':
                this.requestedIdUpdate = true;
                break;
            default:
                this.showMessage(section);
        }
        this.showSupportMessage = true;
    }

    triggerDocumentSelector() {
        if (this.tempDisabled) return;
        const fileInput = document.getElementById('documentSelector');
        fileInput.click();
    }

    clearAddressVerification() {
        this.tempDisabled = true;
        this.fileName = 'Please select document';
        this.fileHolderElement.classList.remove('input-password_warning');
        this.isAddressInReview = false;
        setTimeout(() => this.tempDisabled = false, 500);
    }

    async handleAddressUpload(file) {
        if (this.tempDisabled || !file) return;
        if (this.isAddressInReview) {
            this.isAddressInReview = false;
            this.fileHolderElement.classList.remove('input-password_warning');
        }
        this.fileName = file[0].name;
        const formData = this.imageService.buildFormData(file);
        try {
            this.addressUploading = true;
            const response = await this.imageService.postClientDocument(formData, 2, 2);
            if (response?.id) {
                this.addressUploading = false;
                this.toastService.showToast('Uploaded successfully', 'Your file has been uploaded and was set for review', 'success');
                this.isAddressInReview = true;
                this.fileName = 'Under review';
                this.showPendingMark = true;
                this.fileHolderElement.classList.add('input-password_warning');
                setTimeout(async() => {
                    this.user.addressVerificationInReview = true;
                    this.eventAggregator.publish('user-updated', { user: this.user });
                }, 1000);
                return;
            }
            this.handleAddressErrorState();
        } catch (e) {
            this.handleAddressErrorState();
        }
    }

    catchSubmit() {
        return;
    }

    handleAddressErrorState = () => {
        this.addressUploading = false;
        this.toastService.showToast('Document failed to upload', 'Please review the uploaded file', 'error');
    };
}
