import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode, getAWSBucketEndpoint, baseUrl } from 'environment';
import { Helper } from 'resources/extensions/helper';
import numeral from 'numeral';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';

@autoinject()
export class OrderService {
    path = 'Order';
    amazonProductBaseUrl;
    amazonGameBaseUrl;
    orders;

    constructor(private api: ApiService, private helper: Helper, private camelCaseValueConverter: CamelCaseValueConverter) {
        this.amazonProductBaseUrl = getAWSBucketEndpoint('products');
        this.amazonGameBaseUrl = getAWSBucketEndpoint('games');
    }

    async getAll() {
        if (this.orders) return this.orders;
        this.orders = await this.api.doGet(this.path + '?websiteShortCode=' + websiteShortCode());
        return this.orders;
    }

    getById(id) {
        return this.api.doGet(`${this.path}/${id}?small=true`);
    }

    async startOrder(data) {
        return this.api.doPost(this.path, data);
    }

    async resendReceipt(orderId) {
        return this.api.doPost(this.path + '/' + orderId + '/ResendReceipt');
    }

    async updateIdealStatus(id) {
        return this.api.doPost(`${this.path}/${id}/updateIdealStatus`);
    }

    async updateSofortStatus(id) {
        return this.api.doPost(`${this.path}/${id}/updateSofortStatus`);
    }

    async updateSkrillDirectStatus(id, status) {
        return this.api.doPost(`${this.path}/${id}/${status}/updateSkrillDirectStatus`);
    }

    async updateCheckoutStatus(id, status) {
        return this.api.doPost(`${this.path}/${id}/${status}/updateCheckoutStatus`);
    }

    async getLatestIdealOrder() {
        return this.api.doGet(this.path + '/GetLatestIdealOrder');
    }

    async createTopUpBalanceOrder(order) {
        return await this.api.doPost(`${this.path}/CreateTopUpBalanceOrder`, order);
    }

    async getTotalOrderCountByGameAndProductCategory(gameId, productCategoryId) {
        return await this.api.doGet(`${this.path}/${gameId}/${productCategoryId}/TotalOrderCountByGameAndProductCategory?websiteShortCode=DS`);
    }

    async handleProductRatingSchema(game, productCategory, product?, currency?, overridedPrice?, isSpecificProduct = false) {
        const currencyCategoryName = () => {
            let name = product?.game?.name;
            switch (product?.game?.shortName) {
                case 'POE':
                    name += ' Currency';
                    break;
                case 'FFXIV':
                    name += ' Gil';
                    break;
                case 'NW':
                    name += ' Coins';
                    break;
                case 'EFT':
                    name += ' Roubles';
                    break;
                default:
                    name += ' Gold';
                    break;
            }
            return name;
        };
        const totalOrderCount = await this.getTotalOrderCountByGameAndProductCategory(game.id, productCategory.id);
        let productSchema = `{
                            "@type": "Product",
                            "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}",
                            "name": "${isSpecificProduct && productCategory.name !== 'Currency' ? product.name : isSpecificProduct && productCategory.name === 'Currency' ? currencyCategoryName() : `${game.name} ${productCategory.name}`}",
                            "aggregateRating": {
                                "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}/AggregateRating"
                            }`;
        const imagePath = this.validateImagePathConditions(product, productCategory, game);
        if (imagePath) productSchema += `,\n"image": "${productCategory.name === 'Services' ? this.amazonGameBaseUrl : this.amazonProductBaseUrl}${imagePath}"`;
        else {
            let defaultImagePath = 'icons/';
            switch (product?.productCategory?.name ?? productCategory?.name) {
                case 'Currency':
                    defaultImagePath += 'currency-default.svg';
                    break;
                case 'Items':
                    defaultImagePath += 'sword-no-border.svg';
                    break;
                case 'Accounts':
                    defaultImagePath += 'icon_account.svg';
                    break;
                case 'Services':
                    defaultImagePath += 'circle-arrow.svg';
                    break;
                case 'Swap':
                    defaultImagePath += 'circle-swap.svg';
                    break;
                case 'Skins':
                    defaultImagePath += 'icon_skins.svg';
                    break;
                default:
                    defaultImagePath += 'ds-icon.svg';
                    break;
            }
            productSchema += `,\n"image": "${baseUrl()}${defaultImagePath}"`;
        }
        if (product?.description) productSchema += `,\n"description": "${(this.helper.sanitizeHtml(product.description, true)).replaceAll('"', '')}"`;
        productSchema += `,
                                    "brand": {
                                        "@id": "${baseUrl()}#/schema/Brand/DivicaSales"
                                    }
                                    `;
        if (isSpecificProduct) {
            productSchema += `,
                                        "offers": {
                                            "@id": "${baseUrl()}#/schema/Offer/1",
                                            "seller": {
                                                "@id": "${baseUrl()}#/schema/Organization/DivicaSales"
                                            }
                                        }
                                        `;
        }
        productSchema += '},';

        if (isSpecificProduct) {
            productSchema += `{
                                "@type": "Offer",
                                "@id": "${baseUrl()}#/schema/Offer/1",
                                "url": "${window.location.href}",
                                "priceCurrency": "${currency}",
                                "availability": "http://schema.org/InStock",
                                "price": ${productCategory.name === 'Currency' ? numeral(overridedPrice).format('0.00[000]') : numeral(overridedPrice).format('0.00')}
                            },`;
        }

        productSchema += `{
                            "@type": "AggregateRating",
                            "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}/AggregateRating",
                            "ratingValue": "4.995",
                            "ratingCount": "${totalOrderCount > 0 ? totalOrderCount : 1}",
                            "reviewCount": "${totalOrderCount > 0 ? totalOrderCount : 1}",
                            "bestRating": "5",
                            "worstRating": "1"
                        },
                        {
                            "@type": "Brand",
                            "@id": "${baseUrl()}#/schema/Brand/DivicaSales",
                            "name": "DivicaSales"
                        }`;

        this.helper.combineApplicationLdJsonSchemasIntoOne(productSchema);
    }

    validateImagePathConditions = (product, productCategory, game) => {
        switch (productCategory.name) {
            case 'Currency':
                return product?.schemaImagePath ?? product?.imagePath;
            case 'Services':
                return game?.schemaImagePath ?? game?.gameNavigationIcon;
            default:
                return product?.imagePath;
        }
    };

    async updateOrderStatus(orderId, status) {
        let response = await this.api.doPost(`${this.path}/${orderId}/${status}/UpdateOrderStatus`);
        if (response && typeof response !== 'string') {
            response = this.camelCaseValueConverter.toView(response);
        }
        return response;
    }
}
