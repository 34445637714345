import './faq.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { FaqQuestion, FaqSection } from 'services/models/faq';
import { FaqSectionService } from 'services/faq-section-service';
import { ToastService } from 'services/toast-service';
import { bindable } from 'aurelia-framework';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PageByWebsiteResponse } from 'services/models/page/pageByWebsiteResponse';
import { Router } from 'aurelia-router';
import { Helper } from 'resources/extensions/helper';
import { SimplebarOverride } from 'resources/simplebar_override';
import { getAWSBucketEndpoint } from 'environment';
import { isStaticAsset } from 'resources/constants';

interface ExtendedFaqQuestion extends FaqQuestion {
    isVerificationQuestion?: boolean;
    openByDefault: boolean,
}

interface ExtendedFaqSection extends FaqSection {
    instant: boolean;
    openByDefault: boolean,
    questions: ExtendedFaqQuestion[];
}

type HTMLElementEvent<T extends HTMLElement> = Event & {
    target: T;
};

@autoinject()
export class Faq {
    @bindable isVerificationQuestion = false;
    private sections: ExtendedFaqSection[];
    @bindable({ callback:'searchChanged' }) private search = '';
    faqDialog;
    changeSearchWatch: NodeJS.Timeout;
    toastSearchSent: boolean;
    timeouts: NodeJS.Timeout[];
    pages: PageByWebsiteResponse[];
    relevantQuestions = [];
    constRepeatFor = 3;
    sizeSubscriber: Subscription;
    faqContainer;
    openedQuestion;
    showDrawerContent: boolean;
    isDrawerOpen: boolean;
    width: number;
    sizeChanged: Subscription;
    phone: number;
    hasReseted: boolean;
    currentLeadingIcon = 'search';
    drawerContentSimplebar: HTMLElement;
    prevSearch = '';
    filteredSections = [];
    baseAwsEndpoint: string;
    hasResetedDestkop: boolean;

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private faqSectionService: FaqSectionService,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService,
        private taskQueue: TaskQueue,
        private eventAggregator: EventAggregator,
        private router: Router,
        private helper: Helper,

    ) {
        this.helper = helper;
        this.baseAwsEndpoint = getAWSBucketEndpoint('icons');
        this.helper.getResolutions(this);
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;

    }

    getIconSrc(src: string) {
        return isStaticAsset(src) ? `${src}` : `${this.baseAwsEndpoint}${src}`;
    }

    getIsStaticAsset(src: string) {
        return isStaticAsset(src);
    }

    async activate() {
        [this.sections, this.pages] = await Promise.all([
            this.faqSectionService.getByWebsite(),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);
        this.sections = this.sections.map(section => ({
            ...section,
            openByDefault: false
        }));

        await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Faq')?.id);

        this.filteredSections = structuredClone(this.sections);
    }

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.handleEventSubscriptions();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;

            if (this.width >= this.phone) {
                !this.hasResetedDestkop && this.showDrawerContent && this.resetFiltered();
                this.showDrawerContent = false;
                this.hasResetedDestkop = true;
            } else {
                this.hasResetedDestkop = false;
                if (!this.isDrawerOpen) return;
                this.showDrawerContent = true;
            }
        });
    }

    handleLeadingIcon() {
        if (!this.search) {
            this.currentLeadingIcon = 'search';
            return;
        }
        this.currentLeadingIcon = 'arrow_left_alt';
    }

    reinstanceScrollbar() {
        const instance = SimplebarOverride.instances.get(this.drawerContentSimplebar);
        instance?.unMount?.();
        new SimplebarOverride(this.drawerContentSimplebar);
    }

    handleDrawerToggle(e: CustomEvent) {
        this.isDrawerOpen = Boolean(e?.detail?.value);
        this.showDrawerContent = e?.detail?.value;
        this.resetFiltered();
        this.helper.handleMainPageScroll(this, this.isDrawerOpen);
    }

    searchChanged() {
        this.handleLeadingIcon();
    }

    searchBySection(value: string) {
        const valueFixed = value.trim().toLowerCase();

        if (this.prevSearch === valueFixed) return;

        this.prevSearch = valueFixed;
        const sections = structuredClone(this.sections);
        const filter = sections.map((section) => {
            const filteredQuestions = section.questions.filter(({ question }) => question.toLowerCase().trim().includes(valueFixed));
            if (filteredQuestions.length > 0) {
                section.questions = filteredQuestions;
                return section;
            }
            return;
        })
            .filter(section => section)
            .reduce((prev: ExtendedFaqSection[], section, sectionIndex) => prev.concat({
                ...section,
                openByDefault: sectionIndex === 0,
                instant: true,
                questions: section.questions.map((q, questionIndex) => ({
                    ...q,
                    openByDefault: questionIndex === 0
                }))
            }), []);

        const matchIsOpenByDefault = filter.find(s => s.openByDefault);
        const matchQuestionsIsOpenByDefault = matchIsOpenByDefault && matchIsOpenByDefault.questions.find(q => q.openByDefault);

        const current = this.filteredSections.find(s => s.openByDefault);
        const currentQuestions = current && current.questions.find((q: ExtendedFaqQuestion) => q.openByDefault);

        if (matchIsOpenByDefault?.id === current?.id && matchQuestionsIsOpenByDefault?.id === currentQuestions?.id && filter.length === this.filteredSections.length) return;
        this.filteredSections = filter;
    }

    resetFiltered() {
        this.filteredSections = structuredClone(this.sections).map(section => ({
            ...section,
            openByDefault: false
        }));
    }

    handleOpenByDefault(val: boolean, id: number | string) {
        if (this.filteredSections.filter(s => s.id === id)[0].openByDefault) return;
        if (val) {
            this.filteredSections = this.filteredSections.map(section => ({
                ...section,
                openByDefault: section.id === id
            }));
            return;
        }
    }

    handleLineClamp(e: CustomEvent, id: number | string) {
        if (e.detail.value === null || e.detail.value === undefined) return;
        const headerElement = document.querySelector(`#section-header-${id}`);

        if (e.detail.value) {
            headerElement.classList.remove('line-clamp');
            headerElement.classList.add('hide-line-clamp');
            this.handleOpenByDefault(e.detail.value, id);
            return;
        }
        headerElement.classList.remove('hide-line-clamp');
        headerElement.classList.add('line-clamp');
    }

    getFirstColumnSliced(arr: ExtendedFaqQuestion[]) {
        if (arr.length % 2 === 0) {
            return arr.slice(0, (arr.length / 2));
        }
        return arr.slice(0, (arr.length / 2) + 1);
    }

    getSecondColumnSliced(arr: ExtendedFaqQuestion[]) {
        if (arr.length % 2 === 0) {
            return arr.slice((arr.length / 2));
        }
        return arr.slice((arr.length / 2) + 1);
    }

    handleSearchChange(event: HTMLElementEvent<HTMLInputElement>) {
        this.search = event.target.value;

        this.helper.debounce(this, 'isSearching', 'searchingTimeout', 450, () => {
            if (this.search) {
                this.searchBySection(this.search);
                return;
            }
            setTimeout(() => this.resetFiltered(), 200);
        });
    }

    clearSearch() {
        this.prevSearch = null;
        this.search = null;
    }
}
